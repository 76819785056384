.details {
    box-shadow: inset 0 1px 3px #dbdbdb;
    background: #fafafa;
}

.arrow {
    border: solid;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.disabled {
    background-color: lightgray;
    font-style: italic;
}
